import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Sign from '../Pages/contact';
import ContactUs from '../Pages/ContactUs';
import Forecast from '../Pages/forecast';
import History from '../Pages/history';
import Home from '../Pages/Home';
import Join from '../Pages/join';
import Line from '../Pages/line';
import Objectives from '../Pages/objectives';
import Proposition from '../Pages/proposition';
import Statement from '../Pages/statement';
import Summary from '../Pages/summary';
import Testimony from '../Pages/Testimony';
const RouteRs = () => {
  return (
    <div>
      <Routes>
        <Route path='*' element={<Home/>} />
        <Route path='/' element={<Home />} />
        <Route path='/contact-us' element={< ContactUs />} />
        <Route path='/statement' element={<Statement />} />
        <Route path='/forecast' element={<Forecast />} />
        <Route path='/history' element={<History />} />
        <Route path='/objectives' element={<Objectives />} />
        <Route path='/proposition' element={<Proposition />} />
        <Route path='/summary' element={<Summary />} />
        <Route path='/line' element={<Line />} />
        <Route path='/join' element={<Join />} />
        <Route path="/contact-info" element={<Sign/>}/>
        <Route path="/testimony" element={<Testimony/>}/>
      </Routes>
    </div>
  )
}
export default RouteRs;
