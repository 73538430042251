import React from 'react'
const History = () => {
  return (
    <div className='alls'>
      <div className='history-outer'>
        <div className='historys'>
          <h1>Ministry History<br /> </h1>
          <p>
            We are known over the years as godly people who are ready and willing to go to every extent
            to reach out to people who may be in need either by way of counseling, directions, and
            instructions in the gospel. As the leader of this great ministry, I have had the chance
            to serve under several non profits organizations in the country in the likes of Crossroads360
            or life changing media in Burlington, Ontario as a prayer partner praying for people around North America
            and its environs that calls in. I have served as a minister in the Redeemed Christian Church of God which
            is one of the nonprofit entity’s for several years.  We’ve reached out to people in our communities
            ministering to them about this good news and as a results have decided to take it further under this
            divine mandate, a calling which has always been our lifestyle since childhood to serve our communities
            under this good news platform in the name of the Lord of Hosts  </p>
        </div>
      </div>
    </div>
  )
}

export default History
