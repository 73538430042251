import './App.css';
import { BrowserRouter} from "react-router-dom"
import RouteRs from './main/routes';
import Footer from './Footer/footer';
import NavSLide from './main/navSlide';


function App() {
  return (
    
    <div>
      <BrowserRouter>
     <NavSLide/>
       <RouteRs/>
       <Footer/>
     </BrowserRouter>
    </div>
  );
}

export default App;
