import React from 'react'
import "./footer.css"
import { FaFacebook, FaInstagram, } from "react-icons/fa";
import { Link } from "react-router-dom"
const Footer = () => {
  return (
    <div className='footer-container'>
      <footer>
        <p className="rights">&copy; 2023  Fire Embassy Ministries Canada</p>
        <br />
        <p>Matthew McNair Secondary</p>
        <p>9500 No.4 road</p>
        <p>Richmond, BC V74 2Y9</p>
        <p>Room # 104</p>
        <div className='social-media'>
          <Link to="https://www.facebook.com/fireembassyministriescanada"><FaFacebook /></Link>
          <Link to="https://instagram.com/fireembassyministries?igshid=ZDdkNTZiNTM="><FaInstagram /></Link>
        </div>
      </footer>
    </div>
  )
}
export default Footer
