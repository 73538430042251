import React from 'react'

const Testimony = () => {
  return (
    <div className='test-outer'>
      <div className='outer'>
    <h1>Will Update Soon!!</h1>
    </div>
  </div>
  )
}

export default Testimony
