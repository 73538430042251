import React from 'react'
import './pages.css';
const Statement = () => {
  return (
    <div className='all'>
      <div className='state-outer'>
        <div className='statements'>
          <h1>Mission Statement<br /> </h1>
          <p>
            To be a ministry that will advance faith living in Canada and the rest of the world.
            To be unique and different in the callings of our Lord Jesus Christ where the souls
            of men from all forms in the communities becomes our priority. In that, they may come
            to know the Lord Jesus who saves so, He will save and set them and their households
            free from the vicissitudes of life and life boggling issues for He is the Prince of
            peace (Isaiah 9:6). Because perfect peace is what we need in our individual lives
            and in the communities and above all so they can have rest for their souls when
            everything is said and done on this earth. To be a ministry of salvation, Prayer,
            healing, truth and integrity! </p>
        </div>
      </div>
    </div>
  )
}
export default Statement
