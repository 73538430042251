import React, { useState, useEffect } from 'react'
import { FaChevronCircleLeft, FaChevronCircleRight, FaBars, FaBell, FaUser } from "react-icons/fa";
import { Link } from 'react-router-dom';
const Pictures = [

  "https://www.livingwaters.wa.edu.au/wp-content/uploads/2020/04/shutterstock_1007648908.jpg",

  "https://images.squarespace-cdn.com/content/v1/5c7059fd9d414915398aa241/1550871908825-JXU0H1VNJWTAKJDTER1L/worship.jpg?format=2500w",

  "https://crossroadsag.net/wp-content/uploads/2019/09/Sermon19-0908-Follow-Gods-Charge-2Tim-4.1-6.jpg",

  "https://www.kissfm.es/wp-content/uploads/2021/08/GettyImages-117144618-scaled.jpg",
  
  "https://st2.depositphotos.com/7947384/11304/i/950/depositphotos_113049708-stock-photo-green-grass-field-and-bright.jpg",

  "https://pbs.twimg.com/media/EW5PkuaXkAYr2i7?format=jpg&name=4096x4096",
];
let count = 0;
const Home = () => {

  const [curent, setCurent] = useState(0)
  const HandleRightClick = () => {
    count = (count + 1) % Pictures.length;
    setCurent(count);
  }
  const HandleLeftClick = () => {
    const PricsLenght = Pictures.length;
    count = (curent + PricsLenght - 1) % Pictures.length
    setCurent(count);
  }
  useEffect(() => {
    BeginSlider();
  }, []);
  const BeginSlider = () => {
    setInterval(() => {
      HandleRightClick();
    }, 4000);
  };
  return (
    <div className='home-wrapper'>
      <div className='home-inside'>
        <div className='auto-slide'>
          <img src={Pictures[curent]} alt="Jesus" className='sLides' />
          <div>
            <FaChevronCircleLeft className='lefti' onClick={HandleLeftClick} />
            <FaChevronCircleRight className='righti' onClick={HandleRightClick} />
          </div>
        </div>
        <section className='about'>
          <div className='main'>
            <div className='about-text'>
              <h5>Welcome</h5>
              <p>"A Bible believing Church that believes in God the father, the son and the Holyghost.
                We believe in the salvation of men and life in Christ Jesus. We believe that our communities
                can be changed and transformed by one who saved the world through His blood at the cross.
                We believe in the Power and Authority of the word.</p>
              <p> We believe in Prayers to put the enemy
                where He belongs and take the Land for Jesus...We believe Jesus saves and it doesn't matter your sins,
                He will give you a second chance to be transformed. We believe in the freedom of the spirit and the
                souls of men from demonic and satanic bondages....We believe Jesus Christ is Lord!! Let's fellowship
                together, somebody!!." </p>
              <ul className='giving'>
                <li>
                  <Link to="https://www.paypal.com/donate/?hosted_button_id=QF6NSPXWY6LA8"><button className='btns'>Giving</button></Link>
                </li>
              </ul>
            </div>
            <img src='no limit.jpg'
              className='profile' />
          </div>
        </section>
      </div>
      <div>
        <div className='box'>
          <div className='card'>
            <FaBars className='ar' />
            <h5>Ministry Statement</h5>
            <div className='para'>
              <img src="pictures.jpg"
                className='images' alt='university' />
              <p className='middle'>
              <Link className='butto' to='/statement'>Read More</Link>
              </p>
            </div>
          </div>
          <div className='card'>
            <FaUser className='us' />
            <h5>Ministry Forecast</h5>
            <div className='para'>
              <img src="pictures.jpg"
                className='images' alt='university' />
              <p className='middle'>
                <Link className='butto' to='/forecast'>Read More</Link>
              </p>
            </div>
          </div>
          </div>
      </div>
    </div>
  )
}
export default Home
