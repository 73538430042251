import React, { useState } from 'react'
import "./Navslide.css"
import { Link } from 'react-router-dom'
import { FaBars, FaHome, FaTimes} from "react-icons/fa"
const data = [
    {
        title: 'Mission Statement',
        path: '/statement',
        icon: <FaHome />,
        cName: 'nav-text'
    },
    {
        title: 'Ministry Forecast',
        path: '/forecast',
        icon: <FaHome />,
        cName: 'nav-text'
    },
    {
        title: 'Testimony',
        path: '/testimony',
        icon: <FaHome />,
        cName: 'nav-text'
    },
    {
        title: 'Donate',
        path: 'https://donorbox.org/giving-tithes-and-offerings',
        icon: <FaHome />,
        cName: 'nav-text'
    },
    {
        title: 'GlobalPrayerline',
        path: '/line',
        icon: <FaHome />,
        cName: 'nav-text'
    },
    {
        title: 'Join Us',
        path: '/join',
        icon: <FaHome />,
        cName: 'nav-text'
    },
    {
        title: 'Contact Us',
        path: '/contact-us',
        icon: <FaHome />,
        cName: 'nav-text'
    },
];
const NavSLide = () => {
    const [sideBar, setsideBar] = useState(false)
    const showBar = () => {
        setsideBar(!sideBar)
    }
    return (
        <>
            <div className='nav-bar'>
                <Link to="#" className='menu-bars'> <FaBars onClick={showBar} /></Link>
                <div className='nav-logo'>
                    <Link to="/" className='logo'><img src='no limit.jpg'
                        className='profiles' />&nbsp; Fire Embassy Ministries Canada</Link>
                </div>
                <div className='bar'>
                    <div className='navbar-links'>
                    <ul>
                            <li>
                                <Link to="/testimony">Testimony</Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link to="/join">Join us</Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link to="/contact-us">Contact Us</Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link to="/line">GlobalPrayerline</Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link to="https://donorbox.org/giving-tithes-and-offerings"><button className='btn'>Give</button></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <nav className={sideBar ? "nav-menu active" : "nav-menu"}>
                <ul className='nav-menu-items' onClick={showBar}>
                    <li className='toggle'>
                        <Link to="#" className='menu-bar'><FaTimes /> </Link>
                    </li>
                    {data.map((item, index) => {
                        return (
                            <li key={index} className={item.cName}>
                                <Link to={item.path}><span>{item.icon} {item.title}</span></Link>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </>
    )
}
export default NavSLide
