import React from 'react'
const Proposition = () => {
  return (
    <div className='al'>
      <div className='position-outer'>
        <div className='propositions'>
          <h1>Ministry Proposition<br /> </h1>
          <p>
            We stand by the gospel (good news), integrity (Confidence and Trust), prayers
            (intercession for the land, families, community and all men as our scripture tells us
            (To pray for all men (1 Timothy 2:1-3)), community services (community labor, volunteerism etc),
            liberty, honor, compassion, passion, excellence to advance the Christianity in our day and age
          </p>
        </div>
      </div>
    </div>
  )
}
export default Proposition
