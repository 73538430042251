import React from 'react'
const Objectives = () => {
  return (
    <div className='al'>
      <div className='objective-outer'>
        <div className='objectives'>
          <h1>Ministry Objectives<br /> </h1>
          <p>
            To advance religion in Canada and to advancing adherent of the faith life of our communities,
            the youth, young adults, families through a church planting in the teachings of our Lord Jesus.
            To win soul for our lord Jesus Christ while populating heaven and de-populating hell in our beliefs.
            In that, there is heaven and hell and to spiritually free anyone in the community in bondages under drugs,
            prostitute and any social vices that our gospel is able to set them free from as they avail themselves.
            This will be a free will encounter under the ministry of the Holy Spirit for Christianity advancement
          </p>
        </div>
      </div>
    </div>
  )
}
export default Objectives
