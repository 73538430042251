import React from 'react'

const Summary = () => {
  return (
    <div className='alls'>
      <div className='summary-outer'>
        <div className='summarys'>
          <h1>Ministry Summary<br /> </h1>
          <p>
            We are set out to be the ambassadors of the CROSS of our Lord Jesus
            where we will advance religion adherent of the faith by
            establishing and supporting a place of worship with services held in
            accordance with the tenets and doctrines of Christianity while advancing
            religion, to win souls for Christ Jesus around the nations of the world from Canada.
            To serve the community and show people the mysteries about the good news (Gospel),
            to heal the sick, to stand in for the less privilege and to support the needy emotionally,
            mentally and financially and to be an altar of prayer for the land and for the souls of men,
            for both potential and actual saints in our Christendom, as it’s written, “Praising God,
            and having favour with all the people. </p>
          <p>
            And the Lord added to the church daily such as should be saved”. (Acts 2:47)
            And he said to them, “The harvest is indeed plentiful, but the laborers are few.
            Therefore pray earnestly to the Lord of the harvest to send out laborers into his harvest.
            (Luke 10:2, Matthew 9:37). To be a ministry set out to draw men to the gospel preached in
            season and out of seasons(2 Timothy 4:2) and in the short, medium and long term span and
            for generations yet to come as a house and embassy of faith.  </p>
        </div>
      </div>
    </div>
  )
}

export default Summary
