import React from 'react'

const Forecast = () => {
  return (
    <div className='all'>
      <div className='forecast-outer'>
        <div className='forecasts'>
          <h1>Ministry Forecast<br /> </h1>
          <p>
            To be a ministry that will advance religion in Canada. One that is out to win souls for Christ,
            to be a ministry set out to win more young adults and teach them in the ways of the Lord Jesus
            and to minimize the lifestyles of several social vices in the community and have the hearts of
            the youth ready for Godly living, good community law abiding citizens in this generation and
            the generations to come . Many youth are going astray and we planned to meet them at the door
            of the Cross or at their crossroads interchange and have Jesus Christ as the answer in their
            life boggling questions.  </p>
        </div>
      </div>
    </div>
  )
}

export default Forecast
