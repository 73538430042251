import React from 'react'
const Join = () => {
  return (
    <div className='al'>
      <div className='join-outer'>
        <div className='joins'>
          <p>Worship</p>
          <p> with us</p>
          <h2>Sundays @9am</h2>
          <p>Matthew McNair Secondary</p>
          <p>9500 No.4 road</p>
          <p>Richmond, BC V74 2Y9</p>
          <p>Room # 104</p>
        </div>
      </div>
    </div>
  )
}
export default Join
