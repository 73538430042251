import React, { useState } from 'react'
import "./login.css"
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';
const Sign = () => {
  const [details, setDetails] = useState({
    name: null,
    lastName: null,
    emailAddress: null,
    cellNumber: null
  })
  const handleChanger = (e) => {
    const data = { ...details }
    data[e.target.name] = e.target.value
    setDetails(data)
  }
  const submitData = async (e) => {
    let results = null;
    try {
      results = await ajaxRequest('/backend/index.php?controller=Contact&action=save', 'POST', details);
      if (results.success) {
        console.log('Will contact you back');
      }
    }
    catch (error) {
      console.log(error);
    }
  }
  const ajaxRequest = (url, method, data, headerParams) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          Accept: 'text/html,application/json',
          'Content-Type': 'application/json',
          ...headerParams
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(jsonData => {
          return resolve(jsonData);
        })
        .catch((error) => {
          return reject(error);

        });
    });
  }
  return (
    <div className='overall'>
      <div className='sign-wrap'>
        <div className='sWrap'>
          <div className='logForm' onSubmit={submitData}>
            <div className='heading'>
              <h1 className='in'>Contact Us</h1>
              <div className='social-medias'>
                <Link to="https://www.facebook.com/fireembassyministriescanada"><FaFacebook /></Link>
                <Link to="https://instagram.com/fireembassyministries?igshid=ZDdkNTZiNTM="><FaInstagram /></Link>
              </div>
            </div>
            <div className='detail'>
              <div className='sing-in'>
                <label> Name<span className='special'>*</span>  </label>
                <input type="text"
                  name='name'
                  placeholder='First Name'
                  value={details.name}
                  onChange={handleChanger}
                />
              </div>
              <div className='sing-in'>
                <label>  Last<span className='special'>*</span>  </label>
                <input type="text"
                  name='lastName'
                  placeholder='Last Name'
                  value={details.lastName}
                  onChange={handleChanger}
                />
              </div>
            </div>
            <label>    Email<span className='special'>*</span>  </label>
            <div className='sing-in'>
              <input type="text"
                name='emailAddress'
                placeholder='Enter email address'
                value={details.emailAddress}
                onChange={handleChanger}
              />
            </div>
            <label> Phone Number<span className='special'>*</span> </label>
            <div className='sing-in'>
              <input type="text"
                name='cellNumber'
                placeholder='Enter Phone Number'
                value={details.cellNumber}
                onChange={handleChanger}
              />
            </div>
            <div className='login'>
              <button className='login-btn' onClick={submitData}>
                <span className='circle'>
                  <span className='icon arrow'></span>
                </span>
                <span className='texts' >Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>)
}
export default Sign
