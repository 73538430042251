import React from 'react'
const Line = () => {
  return (
    <div className='als'>
      <div className='line-outer'>
      <div className='line'>
      <img src='pastor.jpg'
        className='profil'/>
      </div>
      </div>
    </div>
  )
}

export default Line